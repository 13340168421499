<template>
    <div class="rn-section-gap bg_color--1">
        <v-container>
            <div class="section-title section-title--2 text-left pa-2">
                <v-row no-gutters>
                    <v-col cols="12">
                        <img style="max-width: 200px!important" src="../../assets/images/sponsor/generalitat.jpg"/>
                    </v-col>
                    <v-col cols="10">
                        <p style="font-size: small!important;">
                            El proyecto “Mejora de la línea de impresión digital” de la mercantil Artes
                            Gráficas DIAGO, S.L., ha sido cofinanciado por la “Conselleria de Economía
                            Sostenible, Sectores Productivos, Comercio y Trabajo” de la Generalitat
                            Valenciana. El importe concedido es el 35% del presupuesto financiable que
                            asciende a 11.760,00 €.
                        </p>
                    </v-col>
                </v-row>
            </div>
        </v-container>
    </div>
</template>

<script>

export default {
    name: "MejoraLineaImpresionDigital",
}
</script>

<style scoped>

</style>