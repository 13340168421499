<template>
  <div class="row mt_dec--30">
    <!-- Start Single Service  -->
    <v-col
      lg="4"
      md="4"
      sm="6"
      cols="12"
      class="mt--30"
      v-for="(service, i) in serviceContent"
      :key="i"
    >
      <div class="single-service service__style--2 bg-color-gray">
        <router-link to="">
          <div class="service">
            <div class="icon">
              <div v-html="iconSvg(service.icon)"></div>
            </div>
            <div class="content">
              <h3 class="heading-title">{{ service.title }}</h3>
              <p>
                {{ service.desc }}
              </p>
            </div>
          </div>
        </router-link>
      </div>
    </v-col>
    <!-- End Single Service  -->
  </div>
</template>

<script>
  import feather from "feather-icons";
  export default {
    data() {
      return {
        serviceContent: [
          {
            icon: "monitor",
            title: "Diseño gráfico",
            desc: `Le damos vida a tus ideas con un diseño gráfico profesional.`,
          },
          {
            icon: "image",
            title: "Impresión digital",
            desc: `Ofrecemos impresión digital de calidad con rapidez y precisión
             para satisfacer todas tus necesidades de impresión.`,
          },
          {
            icon: "layers",
            title: "Impresión offset",
            desc: `La impresión offset garantiza una gran calidad y está disponible para grandes tiradas.`,
          },
          {
            icon: "maximize",
            title: "Gran formato",
            desc: `Proyectos personalizados en gran formato para publicidad, exposiciones y más, con materiales y acabados adaptados a tus necesidades.`,
          },
          {
            icon: "truck",
            title: "Rotulación",
            desc: `Soluciones completas de rotulación: diseño, impresión y montaje de rótulos de alta calidad para todo tipo de necesidades.`,
          },
          {
            icon: "alert-triangle",
            title: "Señalética",
            desc: `Con señalética puedes comunicar, guíar y cautivar. Aplíca impactantes elementos visuales personalizados en tu local, eventos o ferias.`,
          },
          {
              icon: "columns",
              title: "Soportes y Displays",
              desc: `Displays publicitarios a medida para impulsar tu negocio. Descubre soportes portátiles que promocionarán tu marca en cualquier lugar.`,
          },
          {
              icon: "gift",
              title: "Decoración",
              desc: `Descubre la cantidad de materiales sobre los que podemos imprimir y crear un regalo original que se adapte a tus necesidades.`,
          },
          {
              icon: "award",
              title: "Merchandising",
              desc: `Personaliza productos de calidad con tu logo o imagen. Contamos con variedad de opciones y diseños personalizados.`,
          },
        ],
      };
    },
    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
    },
  };
</script>
