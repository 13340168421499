<template>
  <v-row align="start" class="row--35">
    <v-col lg="6" md="6" sm="12" cols="12" order="2" order-md="1">
      <div class="section-title text-left mb--50 mb_sm--30 mb_md--30">
        <h2 class="heading-title">¿Hablamos?</h2>
        <p class="description">
          Cuéntanos tu proyecto y te ayudaremos a hacerlo realidad.
        </p>
      </div>
<!--      <div class="form-wrapper">-->
<!--        <ValidationObserver v-slot="{ handleSubmit }">-->
<!--          <form @submit.prevent="handleSubmit(onSubmit)">-->
<!--            <ValidationProvider-->
<!--              name="name"-->
<!--              rules="required"-->
<!--              v-slot="{ errors }"-->
<!--            >-->
<!--              <label>-->
<!--                <input-->
<!--                  type="text"-->
<!--                  v-model="formData.name"-->
<!--                  placeholder="Nombre *"-->
<!--                />-->
<!--                <span class="inpur-error">{{ errors[0] }}</span>-->
<!--              </label>-->
<!--            </ValidationProvider>-->

<!--            <ValidationProvider-->
<!--              name="email"-->
<!--              rules="required|email"-->
<!--              v-slot="{ errors }"-->
<!--            >-->
<!--              <label>-->
<!--                <input-->
<!--                  type="text"-->
<!--                  rules="required|email"-->
<!--                  v-model="formData.email"-->
<!--                  placeholder="Email *"-->
<!--                />-->
<!--                <span class="inpur-error">{{ errors[0] }}</span>-->
<!--              </label>-->
<!--            </ValidationProvider>-->

<!--            <ValidationProvider-->
<!--              name="subject"-->
<!--              rules="required"-->
<!--              v-slot="{ errors }"-->
<!--            >-->
<!--              <label>-->
<!--                <input-->
<!--                  type="text"-->
<!--                  v-model="formData.subject"-->
<!--                  placeholder="Asunto"-->
<!--                />-->
<!--                <span class="inpur-error">{{ errors[0] }}</span>-->
<!--              </label>-->
<!--            </ValidationProvider>-->

<!--            <ValidationProvider-->
<!--              name="message"-->
<!--              rules="required"-->
<!--              v-slot="{ errors }"-->
<!--            >-->
<!--              <label>-->
<!--                <textarea-->
<!--                  v-model="formData.message"-->
<!--                  placeholder="Mensaje"-->
<!--                ></textarea>-->
<!--                <span class="inpur-error">{{ errors[0] }}</span>-->
<!--              </label>-->
<!--            </ValidationProvider>-->

<!--            <button-->
<!--              class="rn-button-style&#45;&#45;2 btn_solid"-->
<!--              type="submit"-->
<!--              value="submit"-->
<!--            >-->
<!--              Enviar-->
<!--            </button>-->
<!--          </form>-->
<!--        </ValidationObserver>-->
<!--      </div>-->
    </v-col>
<!--    <v-col lg="6" md="6" sm="12" cols="12" order="1" order-md="2">-->
<!--      <div class="thumbnail mb_md&#45;&#45;40 mb_sm&#45;&#45;40">-->
<!--        <img src="../../assets/images/about/about-6.jpg" alt="trydo" />-->
<!--      </div>-->
<!--    </v-col>-->
  </v-row>
</template>

<script>
  import { ValidationObserver } from "vee-validate";
  import { ValidationProvider } from "vee-validate/dist/vee-validate.full.esm";
  export default {
    components: {
      ValidationObserver,
      ValidationProvider,
    },
    data() {
      return {
        formData: {
          name: "",
          email: "",
          subject: "",
          message: "",
        },
      };
    },
    methods: {
      onSubmit() {
        console.log(this.formData);
      },
    },
  };
</script>
